import { DocumentUploadStatus, FeatureFlagType } from "@prisma/client";

import {
  ANNUAL_REVIEW_DEFAULT_CADENCE,
  AnnualReviewMessagingCadenceObject,
  LOAN_ORIGINATION_DEFAULT_CADENCE,
  LoanOriginationMessageCadenceObject,
} from "src/backend/dependencies/email/templates";
import { ImageOperation } from "src/backend/services/transform-pdf/ImageOperation";

// Note: This is the type of the feature flag and should be updated as necessary
export type FeatureFlag = {
  DOCUMENT_PREVIEW_ACTIONS: boolean;
  DEFAULT_NEW_REQUEST_TO_LOAN: boolean;
  SHOW_SHARE_CONTACTS: boolean;
  SHOW_CUSTOM_FREQUENCY_FOR_REVIEWS: boolean;
  SHOW_BACK_BUTTON_FOR_REVIEWS_FROM_PIPELINE: boolean;
  SHOW_SETTINGS_BUTTON_FOR_BORROWER: boolean;
  SHOW_CIF_FIELD: boolean;
  SHOW_CONTACTS_TAB: boolean;
  SHOW_NOTES: boolean;
  SHOW_MASTER_LEASE_NUMBER: boolean;
  SHOW_REMINDERS: boolean;
  ENABLE_MARRIED_ENTITY: boolean;
  SHOW_TO_AND_FROM_ON_DR: boolean;
  SHOW_LOAN_NUMBERS: boolean;
  SHOW_OCR: boolean;
  SHOW_REVIEWED_DOCUMENT_UPLOAD_STATUS: boolean;
  // Why don't we show annual review dates on the pipeline tile for all lenders?
  // Because PA wants to collect docs for a given relationship
  // without regard to a specific review,
  // and they expect these docs to be in the review tile.
  // The best UX for them would be to have a single annual review tile.
  // The plan to achieve this with our current data model is:
  // - Give all of PA's reviews phony "review dates" far in the future, so no new reviews will be made;
  //   DRs will be added to the existing review according to their cadences,
  //   and the rest of our logic will work afaik
  // - Turn SHOW_ANNUAL_REVIEW_DATE_ON_TILE off for PA, so they don't see the phony dates
  // - Turn this flag on for FCN and other lenders
  SHOW_ANNUAL_REVIEW_DATE_ON_TILE: boolean;
  ANNUAL_REVIEW_DEFAULT_MESSAGE_TEMPLATE: AnnualReviewMessagingCadenceObject;
  // This feature flag is used to show the due dates on the emails
  // it is currently only disabled for PAB, by default it is set to true.
  // If any changes are made to this please be sure to update the corresponding lender-config file.
  // in send-monitoring-notification/lender-configs/PAB-config.ts && send-monitoring-notification/lender-configs/FCN-config.ts
  SHOW_DOCUMENT_DUE_DATES_ON_EMAILS: boolean;
  // This flag controls whether to show the end date for review on tile card in borrower portal
  // It is a string so can take a valid date format like 'YYYY', 'MMM YYYY' etc.,
  REVIEW_END_DATE_FORMAT_ON_BORROWER_TILE: string;
  LOAN_ORIGINATION_DEFAULT_MESSAGE_TEMPLATE: LoanOriginationMessageCadenceObject;
  SHOW_MANAGEMENT_TAB: boolean;
  // Disables monitoring tab on loan details page
  SHOW_MONITORING_TAB_ON_LOAN_DETAILS_PAGE: boolean;
  SKIP_LOAN_AND_REVIEW_STEPS: boolean;
  // This allows us to show or hide the pause notification button
  // on the loan details page or annual review details page.
  // Button can be found on the monitoring tab of each
  SHOW_PAUSE_NOTIFICATION_BUTTON: boolean;
  // This feature flag is used to hide or show
  // question answers on the loan details page
  SHOW_INTAKE_ANSWERS: boolean;
  SHOW_DOCUMENT_QUALITY: boolean;
  SHOW_METADATA: boolean;
  DOCUMENT_UPLOAD_AVAILABLE_STATUSES: DocumentUploadStatus[];
  // Enables optional list of pre-processing steps for spread input files
  PRE_PROCESS_STEPS_SPREADS_INPUT_FILES: ImageOperation[];
  LOW_CONFIDENCE_THRESHOLD: number;
  SHOW_SPREADS_HOVER_ICON: boolean;
  ENABLE_OCR_BACKEND_TASKS: boolean;
  FILE_SIZE_LIMIT_MB: number;
  SHOW_NOI_ANALYSIS_GROUP_ASSETS: boolean;
  ENABLE_PDF_SPEADS_EXPORT: boolean;
  ENABLE_DOCUMENT_CLASSIFICATION: boolean;
};

export const FEATURE_FLAG_NAMES: { [key in keyof FeatureFlag]: keyof FeatureFlag } = {
  DOCUMENT_PREVIEW_ACTIONS: "DOCUMENT_PREVIEW_ACTIONS",
  DEFAULT_NEW_REQUEST_TO_LOAN: "DEFAULT_NEW_REQUEST_TO_LOAN",
  SHOW_SHARE_CONTACTS: "SHOW_SHARE_CONTACTS",
  SHOW_CUSTOM_FREQUENCY_FOR_REVIEWS: "SHOW_CUSTOM_FREQUENCY_FOR_REVIEWS",
  SHOW_BACK_BUTTON_FOR_REVIEWS_FROM_PIPELINE: "SHOW_BACK_BUTTON_FOR_REVIEWS_FROM_PIPELINE",
  SHOW_SETTINGS_BUTTON_FOR_BORROWER: "SHOW_SETTINGS_BUTTON_FOR_BORROWER",
  SHOW_CIF_FIELD: "SHOW_CIF_FIELD",
  SHOW_CONTACTS_TAB: "SHOW_CONTACTS_TAB",
  SHOW_NOTES: "SHOW_NOTES",
  SHOW_MASTER_LEASE_NUMBER: "SHOW_MASTER_LEASE_NUMBER",
  SHOW_REMINDERS: "SHOW_REMINDERS",
  ENABLE_MARRIED_ENTITY: "ENABLE_MARRIED_ENTITY",
  SHOW_TO_AND_FROM_ON_DR: "SHOW_TO_AND_FROM_ON_DR",
  SHOW_ANNUAL_REVIEW_DATE_ON_TILE: "SHOW_ANNUAL_REVIEW_DATE_ON_TILE",
  ANNUAL_REVIEW_DEFAULT_MESSAGE_TEMPLATE: "ANNUAL_REVIEW_DEFAULT_MESSAGE_TEMPLATE",
  SHOW_LOAN_NUMBERS: "SHOW_LOAN_NUMBERS",
  SHOW_OCR: "SHOW_OCR",
  SHOW_REVIEWED_DOCUMENT_UPLOAD_STATUS: "SHOW_REVIEWED_DOCUMENT_UPLOAD_STATUS",
  SHOW_DOCUMENT_DUE_DATES_ON_EMAILS: "SHOW_DOCUMENT_DUE_DATES_ON_EMAILS",
  REVIEW_END_DATE_FORMAT_ON_BORROWER_TILE: "REVIEW_END_DATE_FORMAT_ON_BORROWER_TILE",
  LOAN_ORIGINATION_DEFAULT_MESSAGE_TEMPLATE: "LOAN_ORIGINATION_DEFAULT_MESSAGE_TEMPLATE",
  SHOW_MANAGEMENT_TAB: "SHOW_MANAGEMENT_TAB",
  SHOW_MONITORING_TAB_ON_LOAN_DETAILS_PAGE: "SHOW_MONITORING_TAB_ON_LOAN_DETAILS_PAGE",
  SKIP_LOAN_AND_REVIEW_STEPS: "SKIP_LOAN_AND_REVIEW_STEPS",
  SHOW_PAUSE_NOTIFICATION_BUTTON: "SHOW_PAUSE_NOTIFICATION_BUTTON",
  SHOW_INTAKE_ANSWERS: "SHOW_INTAKE_ANSWERS",
  SHOW_DOCUMENT_QUALITY: "SHOW_DOCUMENT_QUALITY",
  SHOW_METADATA: "SHOW_METADATA",
  DOCUMENT_UPLOAD_AVAILABLE_STATUSES: "DOCUMENT_UPLOAD_AVAILABLE_STATUSES",
  PRE_PROCESS_STEPS_SPREADS_INPUT_FILES: "PRE_PROCESS_STEPS_SPREADS_INPUT_FILES",
  LOW_CONFIDENCE_THRESHOLD: "LOW_CONFIDENCE_THRESHOLD",
  SHOW_SPREADS_HOVER_ICON: "SHOW_SPREADS_HOVER_ICON",
  ENABLE_OCR_BACKEND_TASKS: "ENABLE_OCR_BACKEND_TASKS",
  FILE_SIZE_LIMIT_MB: "FILE_SIZE_LIMIT_MB",
  SHOW_NOI_ANALYSIS_GROUP_ASSETS: "SHOW_NOI_ANALYSIS_GROUP_ASSETS",
  ENABLE_PDF_SPEADS_EXPORT: "ENABLE_PDF_SPEADS_EXPORT",
  ENABLE_DOCUMENT_CLASSIFICATION: "ENABLE_DOCUMENT_CLASSIFICATION",
};

export const FEATURE_FLAG_TYPES: { [key in keyof FeatureFlag]: FeatureFlagType } = {
  DOCUMENT_PREVIEW_ACTIONS: FeatureFlagType.BOOLEAN,
  DEFAULT_NEW_REQUEST_TO_LOAN: FeatureFlagType.BOOLEAN,
  SHOW_SHARE_CONTACTS: FeatureFlagType.BOOLEAN,
  SHOW_CUSTOM_FREQUENCY_FOR_REVIEWS: FeatureFlagType.BOOLEAN,
  SHOW_BACK_BUTTON_FOR_REVIEWS_FROM_PIPELINE: FeatureFlagType.BOOLEAN,
  SHOW_SETTINGS_BUTTON_FOR_BORROWER: FeatureFlagType.BOOLEAN,
  SHOW_CIF_FIELD: FeatureFlagType.BOOLEAN,
  SHOW_CONTACTS_TAB: FeatureFlagType.BOOLEAN,
  SHOW_NOTES: FeatureFlagType.BOOLEAN,
  SHOW_MASTER_LEASE_NUMBER: FeatureFlagType.BOOLEAN,
  SHOW_REMINDERS: FeatureFlagType.BOOLEAN,
  ENABLE_MARRIED_ENTITY: FeatureFlagType.BOOLEAN,
  SHOW_TO_AND_FROM_ON_DR: FeatureFlagType.BOOLEAN,
  SHOW_ANNUAL_REVIEW_DATE_ON_TILE: FeatureFlagType.BOOLEAN,
  ANNUAL_REVIEW_DEFAULT_MESSAGE_TEMPLATE: FeatureFlagType.JSON,
  SHOW_DOCUMENT_DUE_DATES_ON_EMAILS: FeatureFlagType.BOOLEAN,
  REVIEW_END_DATE_FORMAT_ON_BORROWER_TILE: FeatureFlagType.TEXT,
  LOAN_ORIGINATION_DEFAULT_MESSAGE_TEMPLATE: FeatureFlagType.JSON,
  SHOW_MANAGEMENT_TAB: FeatureFlagType.BOOLEAN,
  SHOW_MONITORING_TAB_ON_LOAN_DETAILS_PAGE: FeatureFlagType.BOOLEAN,
  SKIP_LOAN_AND_REVIEW_STEPS: FeatureFlagType.BOOLEAN,
  SHOW_PAUSE_NOTIFICATION_BUTTON: FeatureFlagType.BOOLEAN,
  SHOW_INTAKE_ANSWERS: FeatureFlagType.BOOLEAN,
  SHOW_DOCUMENT_QUALITY: FeatureFlagType.BOOLEAN,
  SHOW_METADATA: FeatureFlagType.BOOLEAN,
  DOCUMENT_UPLOAD_AVAILABLE_STATUSES: FeatureFlagType.JSON,
  PRE_PROCESS_STEPS_SPREADS_INPUT_FILES: FeatureFlagType.JSON,
  LOW_CONFIDENCE_THRESHOLD: FeatureFlagType.NUMBER,
  SHOW_SPREADS_HOVER_ICON: FeatureFlagType.BOOLEAN,
  ENABLE_OCR_BACKEND_TASKS: FeatureFlagType.BOOLEAN,
  FILE_SIZE_LIMIT_MB: FeatureFlagType.NUMBER,
  SHOW_NOI_ANALYSIS_GROUP_ASSETS: FeatureFlagType.BOOLEAN,
  ENABLE_PDF_SPEADS_EXPORT: FeatureFlagType.BOOLEAN,
  ENABLE_DOCUMENT_CLASSIFICATION: FeatureFlagType.BOOLEAN,
  SHOW_LOAN_NUMBERS: FeatureFlagType.BOOLEAN,
  SHOW_OCR: FeatureFlagType.BOOLEAN,
  SHOW_REVIEWED_DOCUMENT_UPLOAD_STATUS: FeatureFlagType.BOOLEAN,
};

// Note: All the supported feature flags should be kept here; update as necessary
// and include the default value for that feature flag.
export const DEFAULT_FEATURE_FLAGS: FeatureFlag = {
  DOCUMENT_PREVIEW_ACTIONS: false,
  DEFAULT_NEW_REQUEST_TO_LOAN: false,
  SHOW_SHARE_CONTACTS: true,
  SHOW_CUSTOM_FREQUENCY_FOR_REVIEWS: false,
  SHOW_BACK_BUTTON_FOR_REVIEWS_FROM_PIPELINE: false,
  SHOW_SETTINGS_BUTTON_FOR_BORROWER: false,
  SHOW_CIF_FIELD: false,
  SHOW_CONTACTS_TAB: false,
  SHOW_NOTES: false,
  SHOW_MASTER_LEASE_NUMBER: false,
  SHOW_REMINDERS: false,
  ENABLE_MARRIED_ENTITY: false,
  SHOW_TO_AND_FROM_ON_DR: false,
  SHOW_ANNUAL_REVIEW_DATE_ON_TILE: true,
  SHOW_LOAN_NUMBERS: false,
  SHOW_OCR: false,
  SHOW_REVIEWED_DOCUMENT_UPLOAD_STATUS: false,
  ANNUAL_REVIEW_DEFAULT_MESSAGE_TEMPLATE: ANNUAL_REVIEW_DEFAULT_CADENCE,
  SHOW_DOCUMENT_DUE_DATES_ON_EMAILS: true,
  REVIEW_END_DATE_FORMAT_ON_BORROWER_TILE: "",
  LOAN_ORIGINATION_DEFAULT_MESSAGE_TEMPLATE: LOAN_ORIGINATION_DEFAULT_CADENCE,
  SHOW_MANAGEMENT_TAB: false,
  SHOW_MONITORING_TAB_ON_LOAN_DETAILS_PAGE: false,
  SKIP_LOAN_AND_REVIEW_STEPS: false,
  SHOW_PAUSE_NOTIFICATION_BUTTON: false,
  SHOW_INTAKE_ANSWERS: false,
  SHOW_DOCUMENT_QUALITY: false,
  SHOW_METADATA: false,
  DOCUMENT_UPLOAD_AVAILABLE_STATUSES: [
    DocumentUploadStatus.REQUESTED,
    DocumentUploadStatus.COLLECTED,
    DocumentUploadStatus.COLLECTION_FAILED,
    DocumentUploadStatus.WAIVED,
    DocumentUploadStatus.VERIFIED,
    DocumentUploadStatus.REVIEWED,
  ],
  PRE_PROCESS_STEPS_SPREADS_INPUT_FILES: [],
  LOW_CONFIDENCE_THRESHOLD: 90,
  SHOW_SPREADS_HOVER_ICON: false,
  ENABLE_OCR_BACKEND_TASKS: false,
  FILE_SIZE_LIMIT_MB: 200,
  SHOW_NOI_ANALYSIS_GROUP_ASSETS: false,
  ENABLE_PDF_SPEADS_EXPORT: false,
  ENABLE_DOCUMENT_CLASSIFICATION: false,
};

// An object which maps a feature flag type to a corresponding conversion method
export const CONVERSION = {
  [FeatureFlagType.BOOLEAN]: (value: string) => {
    return value === "true";
  },
  [FeatureFlagType.DATE]: (value: string) => {
    return new Date(value);
  },
  [FeatureFlagType.JSON]: (value: string) => {
    return JSON.parse(value);
  },
  [FeatureFlagType.NUMBER]: (value: string) => {
    return parseInt(value, 10);
  },
  [FeatureFlagType.TEXT]: (value: string) => {
    return value;
  },
};

export interface UpdatedFeatureFlag {
  name: keyof FeatureFlag;
  value: any;
}
