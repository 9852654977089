import * as db from "@prisma/client";
import { DocumentUploadStatus } from "@prisma/client";
import _ from "lodash";
import { DisplayLabel } from "src/utils/label";
import { DocumentPeriodFormat } from "src/Enums/DocumentPeriodFormatEnum";
import { DocumentUploadAutoClassification } from "./DocumentRequest";

// A "DocumentStatusFrequency" is a document upload
// with some document type information along with few document request fields
export interface DocumentUploadInfo extends DocumentTypePeriod {
  rawDocumentUrl: string | null;
  documentUploadId: number;
  lastUploadedOn: string | undefined;
  status: DocumentUploadStatus;
  autoClassification: DocumentUploadAutoClassification | null;

  // fields from  document request
  originalDocType?: string;
  originalYear?: number;
  masterLeaseNumber?: string | null;
  metadata?: Record<string, any>;
  documentRequestId: number;
}

export interface DocumentTypePeriod {
  year: number;
  quarter?: string;
  month?: string;
  half?: string;
  displayName?: string;
  defaultPeriodFormat?: DocumentPeriodFormat;
}

// The "period title" is "Q1 2021", "2023", "Jan 2022", etc.
export const formatPeriodTitleFromFrequency = (
  frequency: DocumentTypePeriod,
  defaultResponse: string = "",
): DisplayLabel => {
  const documentPeriodFormat = _.get(
    frequency,
    "defaultPeriodFormat",
    DocumentPeriodFormat.DEFAULT,
  );
  if (
    documentPeriodFormat === DocumentPeriodFormat.NO_DATE ||
    documentPeriodFormat === DocumentPeriodFormat.DUE_DATE
  ) {
    return defaultResponse;
  } else {
    return `${frequency?.half ?? ""} ${frequency?.quarter ?? ""} ${frequency?.month ?? ""} ${
      frequency.year
    }`.trimStart();
  }
};

export const documentTypePeriodFromDbDocumentType = (d: db.DocumentType): DocumentTypePeriod => {
  const year = d.year;
  // The type db.DocumentType doesn't include
  // "month", "quarter", or "half" yet, but the properties are there;
  // they are set by Prisma middleware from the "attributes" JSONB field.
  // See src/backend/dependencies/prisma.ts.
  const month = _.get(d, "month", undefined);
  const quarter = _.get(d, "quarter", undefined);
  const half = _.get(d, "half", undefined);
  const defaultPeriodFormat = _.get(d, "defaultPeriodFormat", DocumentPeriodFormat.DEFAULT);

  return { year, month, quarter, half, defaultPeriodFormat };
};
